import * as React from 'react';
import fetch from 'isomorphic-unfetch';
import { useTranslation, Trans } from 'react-i18next';
import { useRouter } from 'next/router';
import { Link } from 'Client/components/atoms';
import { OptionItem } from 'Client/types';
import { cpBrandName } from 'Client/constants/brand';
import { proposalNewsSignUp } from 'Client/services/subscription/proposalNewsSignUp';
import { QuestionValue } from 'Client/pages/proposals';
import { PriorityListItem } from 'Client/pages/proposals/types';
import { createUser, fetchUserByEmail } from 'Client/services/user';
import { useProject, useUser, useProposalContext } from 'Client/utils/hooks';
import {
  validateEmail,
  validateEmptyArray,
  parseSyntaxValidationResult,
} from 'Client/utils/validators';
import { savePlanningAppContribution } from 'Client/services/contributions';
import { syntaxValidationRequest } from 'Client/services/validation';
import {
  createUserDemographics,
  updateDemographicsByUserByProject,
  fetchDemographicsByUserByProject,
} from 'Client/services/demographics';
import { sendConfirmationEmail } from 'Client/services/email';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { CONSENT_TYPES } from 'Client/constants/consents';
import {
  getLocalItem,
  setLocalItem,
  CONFIRMATION_EMAIL_SENT_ITEM,
} from 'Client/utils/localstorage';
import { Demographics } from 'Shared/types/demographics';
import {
  ContributionStatus,
  ContributionType,
} from 'Shared/types/contribution';
import useTrackEmailValidation from 'Client/utils/hooks/useAnalytics/useTrackEmailValidation';
import { PlanningAppStage } from 'Shared/types/planningapp';
import { User } from 'Shared/types';
import { BoldInfoIcon } from 'Atoms/Icons';
import { theme } from 'Client/components/theme';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { DaysToComment } from '../DaysToComment';
import {
  Wrapper,
  Header,
  Title,
  FormWrapper,
  StyledQuestionRenderer,
  StyledTextField,
  Description,
  StyledRadioButton,
  OptionsWrapper,
  RadioWrapper,
  StyledButton,
  StyledAutocomplete,
  ButtonWrapper,
  StyledCheckbox,
  CheckboxWrapper,
  AutocompleteWrapper,
  StyledPhoneNumber,
  InfoWrapper,
} from './CommentSection.styles';
import { CommentSectionProps } from './types';
import { scrollToSection } from '../../utils';
import { phoneNumberValidation, emptyField } from '../../utils/validators';
import { AttachFile } from './AttachFile/AttachFile';

export const CommentSection: React.FC<CommentSectionProps> = ({
  daysLeft,
  questions,
  pageContent,
  slug,
  pageName,
  pageId,
  userDemographics,
}) => {
  const trackEmailValidation = useTrackEmailValidation();
  const project = useProject();
  const router = useRouter();
  const [, dispatch] = useProposalContext();
  const { user: loggedUser } = useUser();
  const { t } = useTranslation();
  const { apiToken } = useUtils();
  const { questions: questionsId } = pageContent;
  const viewTypes = questions.viewType?.values as PriorityListItem[];

  const [user, setUser] = React.useState({
    firstName: userDemographics?.firstName || '',
    lastName: userDemographics?.lastName || '',
    email: loggedUser?.email || '',
    phoneNumber: userDemographics?.phoneNumber || '',
    address: userDemographics?.address || '',
    addressInput: '',
    addressId: userDemographics?.addressId || '',
  });
  const [contribution, setContribution] = React.useState({
    [questionsId.siteRelation]: [] as QuestionValue,
    [questionsId.viewType]: 'support',
    [questionsId.materialConsiderations.objection]: [] as QuestionValue,
    [questionsId.materialConsiderations.support]: [] as QuestionValue,
    [questionsId.comment]: '',
  });
  const [proposalNewsConsent, setProposalNewsConsent] = React.useState(false);
  const [newCommonplacesConsent, setNewCommonplacesConsent] =
    React.useState(false);
  const [emailValidation, setEmailValidation] = React.useState(null);
  const [siteValidation, setSiteValidation] = React.useState(null);
  const [materialValidation, setMaterialValidation] = React.useState(null);
  const [commentValidation, setCommentValidation] = React.useState(null);
  const [firstNameValidation, setFirstNameValidation] = React.useState(null);
  const [lastNameValidation, setLastNameValidation] = React.useState(null);
  const [addressValidation, setAddressValidation] = React.useState(null);
  const [phoneValidation, setPhoneValidation] = React.useState(null);

  const [isLoadingContribution, setIsLoadingContribution] =
    React.useState(false);
  const [firstAttemptEmail, setFirstAttemptEmail] = React.useState(user.email);

  const validateFields = async (): Promise<boolean> => {
    let isValid = true;
    const commentValidation = emptyField({
      field: contribution[questionsId.comment] as string,
      fieldName: 'Comment',
    });
    if (commentValidation) {
      setCommentValidation(commentValidation);
      scrollToSection('comment-question');
      isValid = false;
    }

    if (
      contribution[questionsId.viewType] !== 'neutral comment' &&
      !validateEmptyArray(
        contribution[
          questionsId.materialConsiderations[
            contribution[questionsId.viewType] as string
          ] as string
        ] as string[]
      )
    ) {
      setMaterialValidation({
        type: 'error',
        message: 'You should select at least one option',
      });
      scrollToSection('comment-question');
      isValid = false;
    }

    const addressValidation = emptyField({
      field: user.addressId,
      fieldName: 'Address',
    });
    if (addressValidation) {
      setAddressValidation(addressValidation);
      scrollToSection('address-field');
      isValid = false;
    }

    const invalidPhone = phoneNumberValidation({
      phoneNumber: user.phoneNumber,
    });
    if (invalidPhone) {
      setPhoneValidation(invalidPhone);
      scrollToSection('phone-field');
      isValid = false;
    }
    if (!validateEmail(user.email) && !loggedUser?.email) {
      setEmailValidation({
        type: 'error',
        message: 'This does not appear to be a valid email address',
      });
      scrollToSection('email-field');
      isValid = false;
    }
    if (validateEmail(user.email) && !loggedUser?.email) {
      const externalEmailValidation = await syntaxValidationRequest({
        data: user.email,
      }).then(parseSyntaxValidationResult(t));

      trackEmailValidation(user.email, externalEmailValidation);

      const emailChanged = user.email !== firstAttemptEmail;
      if (emailChanged) {
        setFirstAttemptEmail(user.email);
      }
      const invalidEmail =
        (emailChanged && externalEmailValidation?.type === 'warning') ||
        externalEmailValidation?.type === 'error';

      if (invalidEmail) {
        setEmailValidation(externalEmailValidation);
        scrollToSection('email-field');
        isValid = false;
      }
    }

    const lastNameValidation = emptyField({
      field: user.lastName,
      fieldName: 'Last name',
    });
    if (lastNameValidation) {
      setLastNameValidation(lastNameValidation);
      scrollToSection('firstName-field');
      isValid = false;
    }

    const firstNameValidation = emptyField({
      field: user.firstName,
      fieldName: 'First name',
    });
    if (firstNameValidation) {
      setFirstNameValidation(firstNameValidation);
      scrollToSection('firstName-field');
      isValid = false;
    }

    if (
      !validateEmptyArray(contribution[questionsId.siteRelation] as string[])
    ) {
      setSiteValidation({
        type: 'error',
        message: 'You should select at least one option',
      });
      scrollToSection('siteRelation-question');
      isValid = false;
    }
    return isValid;
  };

  const cleanStatus = () => {
    setMaterialValidation(null);
    setEmailValidation(null);
    setSiteValidation(null);
    setCommentValidation(null);
    setFirstNameValidation(null);
    setLastNameValidation(null);
    setAddressValidation(null);
    setPhoneValidation(null);
  };

  const handleSubmitComment = async () => {
    cleanStatus();
    const isValid = await validateFields();
    if (!isValid || isLoadingContribution) return;
    setIsLoadingContribution(true);
    // eslint-disable-next-line no-unused-vars
    const { email, addressInput, ...demographics } = user;
    const userId =
      loggedUser?._id ||
      (
        await fetchUserByEmail(
          user.email,
          project.features.userEndpointsOnGql,
          apiToken
        )
      )?._id;
    const demographicsExists = userId
      ? await fetchDemographicsByUserByProject(userId, project.id)
      : null;

    const gaudiUser = userId
      ? {
          _id: userId,
        }
      : await createUser(
          {
            email: user.email,
          },
          project.features.userEndpointsOnGql,
          apiToken
        );

    const demographic =
      userDemographics || demographicsExists
        ? await updateDemographicsByUserByProject({
            projectName: project.id,
            userId: gaudiUser._id,
            updates: demographics as Partial<Demographics>,
          })
        : await createUserDemographics({
            project: project.id,
            user_id: gaudiUser._id,
            ...demographics,
          });

    const { materialConsiderations, ...allQuestions } = questions;
    const questionsArray = [
      ...Object.values(materialConsiderations).map((question) => question),
      ...Object.values(allQuestions).map((question) => question),
    ];
    const isPrivate =
      project?.features?.glaPlanningApps &&
      pageContent?.stage !== PlanningAppStage.stage3;
    const savedContribution = await savePlanningAppContribution({
      project,
      user: gaudiUser as User,
      userId: gaudiUser._id,
      proposalSlug: slug,
      answers: contribution,
      questions: questionsArray,
      status: loggedUser ? ContributionStatus.CONFIRMED : null,
      userDemographics: demographic,
      pageId,
      addIsSynched: project?.features?.glaPlanningApps && true,
      isPrivate,
    });

    const confEmailSent = getLocalItem(CONFIRMATION_EMAIL_SENT_ITEM);
    if (!confEmailSent || confEmailSent !== gaudiUser._id) {
      const res = await sendConfirmationEmail({
        email: user.email,
        contributionId: savedContribution._id,
        contributionType: ContributionType.COMMENT,
        lang: savedContribution.language || 'en-GB',
        userId: loggedUser?._id, // only when logged in
      });
      if (res && res?.user && res?.user?._id) {
        setLocalItem(CONFIRMATION_EMAIL_SENT_ITEM, res.user._id);
      }
    }
    if (proposalNewsConsent) {
      await proposalNewsSignUp({
        email: user.email,
        pageId,
        project,
        planApp: {
          planAppAddress: pageName,
          planAppId: pageContent.reference,
        },
      });
    }

    if (newCommonplacesConsent) {
      await fetch(`/api/consent`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          consents: [
            {
              type: CONSENT_TYPES.NEW_COMMONPLACES,
            },
          ],
        }),
      });
    }

    if (loggedUser) {
      router.push('/thanks');
    } else {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_ID,
        contributionId: savedContribution._id,
      });
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_TYPE,
        contributionType: ContributionType.COMMENT,
      });
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_SIGNUP_EMAIL,
        signupEmail: user.email,
      });
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_CUSTOM_TITLE,
        customTitle: {
          planningOfficerName: pageContent.caseOfficer,
          customerName: project.customer,
        },
      });

      router.push('/confirm-email');
    }
  };

  const handleQuestionsValues = (qId: string, val: QuestionValue) => {
    setContribution({
      ...contribution,
      [qId]: val,
    });
  };

  const handleViewType = (viewType: string) => {
    setContribution({
      ...contribution,
      [questionsId.viewType]: viewType,
      [questionsId.materialConsiderations.support]: [] as QuestionValue,
      [questionsId.materialConsiderations.objection]: [] as QuestionValue,
    });
  };

  return (
    <Wrapper id="comment-section" data-testid="PlanningApp-CommentSection">
      <Header>
        <Title>{t('SUBMIT A COMMENT')}</Title>
        {project?.features?.glaPlanningApps &&
          pageContent?.stage === PlanningAppStage.stage3 && (
            <InfoWrapper>
              <BoldInfoIcon color={theme.colorMappings.brand} />
              <Trans>
                <p>
                  Your comment <strong>will be published</strong> online. Do not
                  include any information that is sensitive within your comment
                  that you do not wish to be published. More information can be
                  found{' '}
                  <Link
                    href="https://www.london.gov.uk/commenting-planning-application"
                    to="https://www.london.gov.uk/commenting-planning-application"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </Link>
                  .
                </p>
              </Trans>
            </InfoWrapper>
          )}
        {daysLeft > 0 && !project?.features?.glaPlanningApps && (
          <DaysToComment daysLeft={daysLeft} />
        )}
      </Header>

      <FormWrapper>
        <StyledQuestionRenderer
          id="siteRelation-question"
          question={questions.siteRelation}
          onChange={handleQuestionsValues}
          status={siteValidation}
        />

        <StyledTextField
          id="firstName-field"
          label="First name:"
          autocomplete="firstname"
          showLabel
          type="text"
          placeholder="First name"
          value={user.firstName}
          handleChange={(e) => {
            setUser({
              ...user,
              firstName: String(e.target.value),
            });
          }}
          status={firstNameValidation}
        />

        <StyledTextField
          id="lastName-field"
          label="Last name:"
          autocomplete="lastname"
          showLabel
          type="text"
          placeholder="Last name"
          value={user.lastName}
          handleChange={(e) => {
            setUser({
              ...user,
              lastName: String(e.target.value),
            });
          }}
          status={lastNameValidation}
        />

        {!loggedUser?.email && (
          <StyledTextField
            id="email-field"
            label="Email:"
            autocomplete="email"
            showLabel
            type="email"
            placeholder="Your email address"
            status={emailValidation}
            handleChange={(e) => {
              setUser({
                ...user,
                email: String(e.target.value).toLowerCase(),
              });
            }}
          />
        )}
        <StyledPhoneNumber
          id="phone-field"
          label="Phone no:"
          autocomplete="phone"
          showLabel
          type="tel"
          status={phoneValidation}
          placeholder="Your phone number"
          value={user.phoneNumber}
          handleChange={(e) => {
            setPhoneValidation(null);
            setUser({
              ...user,
              phoneNumber: String(e.target.value),
            });
          }}
        />
        <AutocompleteWrapper>
          <StyledAutocomplete
            // autocomplete="address"
            id="address-field"
            label="Address:"
            searchFor={['address']}
            defaultValue={
              userDemographics
                ? {
                    value: user.addressId,
                    label: user.address,
                  }
                : null
            }
            handleChange={(opt: OptionItem) => {
              setUser((prevState) => {
                return {
                  ...prevState,
                  address: String(opt?.value || ''),
                  addressId: String(opt?.id || ''),
                };
              });
            }}
            placeholder="Start typing address or postcode"
            isCreatable={false}
            status={addressValidation}
          />
        </AutocompleteWrapper>

        <Description>
          <Trans>
            We only accept genuine comments. We’ll send you an email to confirm
            your comment. Your email and phone number will not be made public.
            Read our
            <Link
              to="https://www.commonplace.is/privacy-policy"
              color="primaryButtonBackground"
              newTab
              external
            >
              privacy policy.
            </Link>
          </Trans>
        </Description>

        <RadioWrapper>
          <p aria-labelledby="viewType">{questions.viewType.label}</p>
          <OptionsWrapper radioGroup="viewType">
            {viewTypes.map((value) => (
              <StyledRadioButton
                key={value?.value}
                label={value?.label}
                checked={
                  contribution[questionsId.viewType] ===
                  value?.value?.toLowerCase()
                }
                name={questions.viewType.id}
                labelPlacement="end"
                onChange={() => {
                  handleViewType(value?.value?.toLowerCase());
                }}
              />
            ))}
          </OptionsWrapper>
        </RadioWrapper>

        {contribution[questionsId.viewType] !== 'neutral comment' && (
          <StyledQuestionRenderer
            data-testid="material-considerations-question"
            question={
              questions.materialConsiderations[
                contribution[questionsId.viewType] as string
              ]
            }
            onChange={handleQuestionsValues}
            status={materialValidation}
          />
        )}

        <StyledQuestionRenderer
          id="comment-question"
          question={questions.comment}
          value={contribution[questionsId.comment]}
          status={commentValidation}
          rows={7}
          rowsMax={7}
          onChange={handleQuestionsValues}
        />
        {questions?.attachFile && (
          <AttachFile
            attachFile={questions.attachFile}
            onChange={handleQuestionsValues}
          />
        )}

        <CheckboxWrapper>
          {pageContent?.status !== 'Decision granted' && (
            <StyledCheckbox
              id="proposal-news-checkbox"
              label="Keep me updated on this planning application"
              checked={proposalNewsConsent}
              onChange={() => setProposalNewsConsent(!proposalNewsConsent)}
            />
          )}
          <StyledCheckbox
            id="new-commonplaces-checkbox"
            label={
              t(
                '{{cpBrandName}} helps various organisations to shape better places. ',
                {
                  cpBrandName,
                }
              ) + project?.features?.glaPlanningApps
                ? t(
                    'Keep me updated about any new planning application in the London area.'
                  )
                : t(
                    'Keep me updated about any new {{cpBrandName}} in the Watford area.',
                    {
                      cpBrandName,
                    }
                  )
            }
            checked={newCommonplacesConsent}
            onChange={() => setNewCommonplacesConsent(!newCommonplacesConsent)}
          />
        </CheckboxWrapper>

        <ButtonWrapper>
          <StyledButton
            type="submit"
            onClick={handleSubmitComment}
            disabled={isLoadingContribution}
            data-testid="submit-comment-button"
          >
            {t('Submit comment')}
          </StyledButton>
        </ButtonWrapper>
      </FormWrapper>
    </Wrapper>
  );
};
