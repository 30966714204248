import * as React from 'react';
import { useRouter } from 'next/router';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/minimal';
import { isValidJson } from 'Shared/utils';
import { ContributionsFilters, SortOptions } from 'Shared/types/dashboard';
import { FilterOption } from 'Shared/types/filters';
import { SidePanel } from 'Molecules';
import { DashboardPagesTemplate as Template } from 'Templates';
import GlobalFiltersV2 from 'Pages/dashboard/components/GlobalFiltersV2/GlobalFilters';
import { useProject, useUser } from 'Client/utils/hooks';
import { Permissions } from 'Client/constants/permissions';
import { GroupsPage } from 'Pages/dashboard/groups';
import { HiddenColumns } from 'Pages/dashboard/components/Table';
import { userHasPermission } from 'Client/utils/user';
import {
  handleTabUrls,
  redirectToTab,
  mapFilters,
  getTopFiltersFromUrl,
} from '../utils';
import { useToggle } from './hooks';
import { CREATE_GROUP, GROUP_AND_EMAIL } from './constants';
import { ContributionsPageProps, CreateGroupFeedbackMessage } from './types';
import { getSelectedRowsCount } from './utils';
import {
  Summary,
  Sentiments,
  CreateGroup,
  ChartsWrapper,
  FeedbackMessage,
} from './components';
import {
  TabNavigation,
  TableWrapper,
  ActionBar,
  CommentReply,
  ExportModal,
  ProposalDropDown,
  DateFilter,
} from '../components';
import {
  HeaderContainer,
  HeaderDivider,
  DropDownGroup,
  MainContentContainer,
} from './ContributionsPage.styles';
import {
  EXPORT_MUTATION,
  GET_CONTRIBUTIONS_QUERY,
  FILTER_QUERY,
} from './ContributionsPage.gql';
import { updateQueryWithoutReload } from '../utils/updateQuery';

const ContributionsPage: React.FC<ContributionsPageProps> = ({
  tabOptions,
  pagesDropdownInfo,
}: ContributionsPageProps) => {
  const { _id, id } = useProject();
  const projectId = _id.toString();

  const { user } = useUser();
  const { t } = useTranslation('customer');
  const router = useRouter();
  const { query } = router;
  const [exportRequest] = useMutation(EXPORT_MUTATION);

  const {
    lastIndex,
    tab,
    nestedFilters: urlNestedFilters,
    topFilters: urlTopFilters,
  } = query;

  const [contributionsCount, setContributionsCount] =
    React.useState<number>(null);
  const [commentsCount, setCommentsCount] = React.useState<number>(null);
  const [agreementsCount, setAgreementsCount] = React.useState<number>(null);
  const [dashboardCommentsCount, setDashboardCommentsCount] =
    React.useState<number>(null);
  const [fetching, setFetching] = React.useState(false);
  const [dashboardComments, setDashboardComments] = React.useState([]);
  const [dashboardMapComments, setDashboardMapComments] = React.useState([]);
  const [allCommentsAnswers, setAllCommentsAnswers] = React.useState({});
  const [baseTableHeader, setBaseTableHeaders] = React.useState([]);
  const [locales, setLocales] = React.useState([]);

  const [filterOptions, setFilterOptions] = React.useState<FilterOption[]>([]);
  const [currentTab, setCurrentTab] = React.useState<number>(1);
  const [isGlobalFiltersOpen, setIsGlobalFiltersOpen] =
    React.useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] =
    React.useState<boolean>(false);
  const [exported, setExported] = React.useState<boolean>(false);
  const [exporting, setExporting] = React.useState<boolean>(false);
  const [selectedActionBarOption, setSelectedActionBarOption] =
    React.useState<string>(null);
  const [selectedContributionsIds, setSelectedContributionsIds] =
    React.useState<Array<string>>([]);
  const [flatRowsIds, setFlatRowsIds] = React.useState<Array<string>>([]);
  const [isAllRowsCheckboxSelected, setIsAllRowsCheckboxSelected] = useToggle();
  const [hiddenColumns, setHiddenColumns] = React.useState<
    Array<HiddenColumns>
  >([]);
  const [tableSort, setTableSort] = React.useState<SortOptions>(null);
  const [appliedFilters, setAppliedFilters] =
    React.useState<ContributionsFilters>({});
  const [createGroupFeedbackMessage, setCreateGroupFeedbackMessage] =
    React.useState<CreateGroupFeedbackMessage>({
      messageType: 'error',
      groupName: '',
      isOpen: false,
    });
  const [lastIndexState, setLastIndexState] = React.useState<number>(
    Number(lastIndex)
  );
  const [contributionsIds, setContributionsIds] = React.useState<
    { contributionId: string; pageId: string }[]
  >([]);

  const currentSelectedContributions = getSelectedRowsCount({
    isAllRowsCheckboxSelected,
    totalFilteredRows: dashboardCommentsCount,
    allRenderedRows: flatRowsIds,
    selectedRows: selectedContributionsIds,
  });

  const groupItems = [
    {
      label: CREATE_GROUP,
      action: () => setSelectedActionBarOption(CREATE_GROUP),
      dataOnboarding: 'dashboard-create-group',
    },
    {
      label: GROUP_AND_EMAIL,
      action: () => setSelectedActionBarOption(GROUP_AND_EMAIL),
      dataOnboarding: 'dashboard-create-group-email',
    },
  ];

  const ActionBarButtons = [
    { label: 'Group', dropdown: true, dropdownItems: groupItems },
  ];

  const { data: filtersData } = useQuery(FILTER_QUERY, {
    variables: {
      getCommentsAnswersForProjectInput: projectId,
      getProjectSettingsInput: {
        projectId,
      },
      getContributionsFilterOptionsInput: {
        projectId,
        filters: appliedFilters,
      },
    },
  });

  const { data, loading, fetchMore } = useQuery(GET_CONTRIBUTIONS_QUERY, {
    variables: {
      getTotalContributionsForProjectInput: {
        projectId,
        filters: appliedFilters,
      },
      getTotalCommentsForProjectInput: {
        projectId,
        filters: [],
      },
      getTotalAgreementsForProjectInput: {
        projectId,
        filters: appliedFilters,
      },
      getContributionsTableColumnsInput: {
        projectId,
        filters: appliedFilters,
      },
      getPopulatedQuestionsForProjectInput: {
        projectId,
        lang: 'en-GB',
        filters: appliedFilters,
      },
      getTotalDashboardCommentsForProjectInput: {
        projectId,
        filters: appliedFilters,
      },
      getDashboardCommentsForProjectInput: {
        projectId,
        filters: appliedFilters,
        skip: 0,
        limit: 100,
        ...(tableSort && {
          sort: {
            sortKey: tableSort.columnId,
            sortDirection: tableSort.sortDirection === 'asc' ? 1 : -1,
          },
        }),
      },
      getDashboardMapCommentsForProjectInput: {
        projectId,
        filters: appliedFilters,
        skip: 0,
        limit: dashboardCommentsCount,
      },
    },
  });

  const onAllRowsCheckboxToggle = () => setIsAllRowsCheckboxSelected();
  const onTabChange = (tabId: string) => {
    redirectToTab({ newTab: tabId, page: 'contributions', router });
  };
  const onGlobalFilterOpen = () => setIsGlobalFiltersOpen(true);
  const onGlobalFilterClose = () => setIsGlobalFiltersOpen(false);
  const onExportModalClose = () => {
    setExported(false);
    setIsExportModalOpen(false);
  };

  const applyFilters = React.useCallback(() => {
    const topFilters = getTopFiltersFromUrl({ router });
    const nestedFilters = isValidJson(urlNestedFilters as string)
      ? JSON.parse(urlNestedFilters as string)
      : [];
    const filters = mapFilters(nestedFilters, topFilters);
    setAppliedFilters(filters);
  }, [router, urlNestedFilters]);

  const loadMoreData = async (_lastId: string, lastIndex: number) => {
    setLastIndexState(lastIndex);
    updateQueryWithoutReload({ lastIndex: String(lastIndex) });
  };

  const handleModalExport = async (exportOptions) => {
    try {
      setExporting(true);
      const toSend = {
        exportOptions,
        projectName: id,
        filters: appliedFilters,
        sortOptions: tableSort,
        projectId,
        hiddenColumns,
        user,
        reference: 'contribution',
      };

      exportRequest({ variables: { dashboardCsvExportInput: toSend } });
      setExported(true);
      setExporting(false);
    } catch (error) {
      setExported(false);
      setExporting(false);
      captureException(
        `error in handleModalExport @ ContributionsPage.tsx : ${error}`
      );
    }
  };

  const handleCloseFeedbackMessage = () => {
    setCreateGroupFeedbackMessage((previous) => {
      return { ...previous, isOpen: false };
    });
  };

  React.useEffect(() => {
    const setInitialData = () => {
      const {
        getTotalContributionsForProject: contributionsCount,
        getTotalCommentsForProject: commentsCount,
        getTotalAgreementsForProject: agreementsCount,
        getTotalDashboardCommentsForProject: dashboardCommentsCount,
        getDashboardCommentsForProject: dashboardComments,
        getDashboardMapCommentsForProject: dashboardMapComments,
        getContributionsTableColumnsV2: baseTableHeaders,
      } = data;

      setContributionsCount(contributionsCount);
      setCommentsCount(commentsCount);
      setAgreementsCount(agreementsCount);
      setDashboardCommentsCount(dashboardCommentsCount);

      setDashboardComments(dashboardComments);
      setDashboardMapComments(dashboardMapComments);
      setBaseTableHeaders(baseTableHeaders);
    };

    if (!appliedFilters?.topFilters?.proposals) {
      return;
    }

    if (data && Object.keys(data).length > 0) {
      setInitialData();
    }
  }, [data, tableSort]);

  React.useEffect(() => {
    const loadMore = async (limit: number) => {
      await fetchMore({
        variables: {
          getDashboardCommentsForProjectInput: {
            projectId,
            filters: appliedFilters,
            skip: 0,
            limit,
            ...(tableSort && {
              sort: {
                sortKey: tableSort.columnId,
                sortDirection: tableSort.sortDirection === 'asc' ? 1 : -1,
              },
            }),
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const { getDashboardCommentsForProject: dashboardComments } =
            fetchMoreResult;

          setDashboardComments([...dashboardComments]);
        },
      });
    };
    (async () => {
      if (lastIndexState) {
        setFetching(true);
        await loadMore(lastIndexState + 100 || 100);
        setFetching(false);
      }
    })();
  }, [lastIndexState, lastIndex]);

  /* this logic is to be extract to the global filters component soon */
  React.useEffect(() => {
    if (filtersData) {
      const {
        getProjectSettings: projectSettings,
        getCommentsAnswersForProject: allCommentsAnswers,
        getContributionsFilterOptions: contributionsFilterOptions,
      } = filtersData;

      const locales =
        projectSettings && projectSettings.locales
          ? projectSettings.locales
          : [];
      setLocales(locales);
      setAllCommentsAnswers(allCommentsAnswers);
      setFilterOptions(contributionsFilterOptions);
    }
  }, [filtersData]);

  React.useEffect(() => {
    applyFilters();
  }, [applyFilters, urlNestedFilters, urlTopFilters]);

  React.useEffect(() => {
    handleTabUrls({
      page: 'contributions',
      tabInQuery: tab as string,
      router,
      tabOptions,
      setTab: setCurrentTab,
    });
  }, [tab]);

  React.useEffect(() => {
    if (dashboardMapComments) {
      setContributionsIds(dashboardMapComments);
    }
  }, [dashboardMapComments]);

  return (
    <Template currentTab={'Contributions'}>
      <HeaderContainer>
        <HeaderDivider>
          <Summary
            title="Contributions"
            commentsCount={dashboardCommentsCount}
            agreementsCount={agreementsCount}
            contributionsCount={contributionsCount}
          />
          <Sentiments />
        </HeaderDivider>
        <HeaderDivider>
          <DropDownGroup>
            <ProposalDropDown
              proposals={pagesDropdownInfo}
              isLoading={false}
              page="contributions"
            />
            <DateFilter
              pageType="contributions"
              appliedFilters={appliedFilters}
            />
          </DropDownGroup>
        </HeaderDivider>
      </HeaderContainer>
      <TabNavigation
        selectedTab={currentTab}
        tabOptions={tabOptions}
        onTabChange={onTabChange}
        onFilterClick={onGlobalFilterOpen}
        isExportEnabled={
          userHasPermission(user, Permissions.EXPORT_CONTRIBUTIONS) &&
          currentTab === 1
        }
        onExportClick={() => setIsExportModalOpen(true)}
      />
      <MainContentContainer data-testid="ContributionsPage-MainContentContainer">
        {currentTab === 1 && (
          <>
            <TableWrapper
              baseTableHeaders={baseTableHeader}
              loading={loading}
              rowsData={dashboardComments}
              filteredRows={dashboardComments.length}
              dataTotal={commentsCount}
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
              setSelectedRowsIds={setSelectedContributionsIds}
              loadMoreData={loadMoreData}
              tableSort={tableSort}
              setTableSort={setTableSort}
              onAllRowsCheckboxToggle={onAllRowsCheckboxToggle}
              isAllRowsCheckboxSelected={isAllRowsCheckboxSelected}
              setFlatRowsIds={setFlatRowsIds}
              dataName={t('comments')}
              tableType="contributions"
              fetching={fetching}
            />
          </>
        )}
        {currentTab === 2 && (
          <ChartsWrapper
            projectId={projectId}
            appliedFilters={appliedFilters}
            dataTotal={commentsCount}
            filteredRows={dashboardCommentsCount}
            dataName={t('comments')}
            contributionsIds={contributionsIds}
          />
        )}
        {currentTab === 3 && <GroupsPage />}
      </MainContentContainer>
      <SidePanel
        isOpen={Boolean(
          selectedActionBarOption === CREATE_GROUP ||
            selectedActionBarOption === GROUP_AND_EMAIL
        )}
      >
        <CreateGroup
          reference="contribution"
          currentSelectedRows={currentSelectedContributions}
          selectedRowsIds={selectedContributionsIds}
          allRows={flatRowsIds}
          closeSidePanel={() => setSelectedActionBarOption(null)}
          setCreateGroupFeedbackMessage={setCreateGroupFeedbackMessage}
          filters={appliedFilters}
          isGroupEmail={selectedActionBarOption === GROUP_AND_EMAIL}
          isAllRowsCheckboxSelected={isAllRowsCheckboxSelected}
        />
      </SidePanel>
      <SidePanel isOpen={createGroupFeedbackMessage.isOpen}>
        <FeedbackMessage
          {...createGroupFeedbackMessage}
          closeSidePanel={handleCloseFeedbackMessage}
          setCurrentTab={setCurrentTab}
        />
      </SidePanel>
      {Boolean(selectedContributionsIds.length && currentTab === 1) && (
        <ActionBar
          totalSelected={currentSelectedContributions}
          buttons={ActionBarButtons}
        >
          <CommentReply
            contributions={dashboardComments}
            selectedContributions={selectedContributionsIds}
          />
        </ActionBar>
      )}

      <GlobalFiltersV2
        isOpen={isGlobalFiltersOpen}
        onModalClose={onGlobalFilterClose}
        pageType="contributions"
        additionalFilterable={{
          language: locales,
        }}
        allAnswers={allCommentsAnswers}
        filterOptions={filterOptions}
      />

      {isExportModalOpen && (
        <ExportModal
          pageType="contributions"
          exporting={exporting}
          exported={exported}
          isOpen={isExportModalOpen}
          onModalClose={onExportModalClose}
          onExport={handleModalExport}
          filters={appliedFilters}
        />
      )}
    </Template>
  );
};

export { ContributionsPage };
