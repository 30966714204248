import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ContributionSentimentCheck } from 'Shared/types/contribution';
import { Checkbox, CheckBoxContainer } from './FiltersView.styles';
import { SentimentFilterProps } from './types';

export const SentimentFilter = ({
  sentimentCount,
  setContribution,
  setSentiment,
  sentiments,
}: SentimentFilterProps) => {
  const { t } = useTranslation();
  return (
    <CheckBoxContainer>
      <Checkbox
        data-testid="ContributionTabCheckbox"
        label={t('Positive • {{positiveContributions}}', {
          positiveContributions: sentimentCount['100'] || 0,
        })}
        onChange={(e) => {
          const { checked } = e.target;
          setSentiment({ positiveChecked: checked });
          setContribution({ positiveChecked: checked });
        }}
        status={{ show: false }}
        checked={sentiments.positiveChecked}
        sentiment={ContributionSentimentCheck.sentimentPositive}
      />

      <Checkbox
        data-testid="ContributionTabCheckbox"
        label={t('Mostly positive • {{mostlyPositiveContributions}}', {
          mostlyPositiveContributions: sentimentCount['75'] || 0,
        })}
        onChange={(e) => {
          const { checked } = e.target;
          setSentiment({ mostlyPositiveChecked: checked });
          setContribution({ mostlyPositiveChecked: checked });
        }}
        status={{ show: false }}
        checked={sentiments.mostlyPositiveChecked}
        sentiment={ContributionSentimentCheck.sentimentMostlyPositive}
      />

      <Checkbox
        data-testid="ContributionTabCheckbox"
        label={t('Neutral • {{neutralContributions}}', {
          neutralContributions: sentimentCount['50'] || 0,
        })}
        onChange={(e) => {
          const { checked } = e.target;
          setSentiment({ neutralChecked: checked });
          setContribution({ neutralChecked: checked });
        }}
        status={{ show: false }}
        checked={sentiments.neutralChecked}
        sentiment={ContributionSentimentCheck.sentimentNeutral}
      />

      <Checkbox
        data-testid="ContributionTabCheckbox"
        label={t('Mostly negative • {{mostlyNegativeContributions}}', {
          mostlyNegativeContributions: sentimentCount['25'] || 0,
        })}
        onChange={(e) => {
          const { checked } = e.target;
          setSentiment({ mostlyNegativeChecked: checked });
          setContribution({ mostlyNegativeChecked: checked });
        }}
        status={{ show: false }}
        checked={sentiments.mostlyNegativeChecked}
        sentiment={ContributionSentimentCheck.sentimentMostlyNegative}
      />

      <Checkbox
        data-testid="ContributionTabCheckbox"
        label={t('Negative • {{negativeContributions}}', {
          negativeContributions: sentimentCount['0'] || 0,
        })}
        onChange={(e) => {
          const { checked } = e.target;
          setSentiment({ negativeChecked: checked });
          setContribution({ negativeChecked: checked });
        }}
        status={{ show: false }}
        checked={sentiments.negativeChecked}
        sentiment={ContributionSentimentCheck.sentimentNegative}
      />
    </CheckBoxContainer>
  );
};
