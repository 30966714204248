import * as React from 'react';
import { captureException, captureMessage } from '@sentry/react';
import { CircularProgress } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import fetch from 'isomorphic-unfetch';
import { useLazyQuery, useMutation } from '@apollo/client';
import { fromLonLat } from 'ol/proj';
import Draw from 'ol/interaction/Draw';
import { UPLOAD_FILE_TO_S3 } from 'Client/utils/gql/mutations.gql';
import { QuestionRenderer } from 'Client/components/organisms';
import { Answers, Question, QuestionValue } from 'Client/pages/proposals/types';
import type { MapBoxApiResponse } from 'Shared/types';
import {
  useProject,
  useUser,
  useAnalytics,
  MixpanelEventTypes,
  useMap,
} from 'Client/utils/hooks';
import {
  ContributionStatus,
  ContributionType,
} from 'Shared/types/contribution';
import { handleProposalEndFlow } from 'Client/services/contributionFlow';
import { makeAnswersForGaudi } from 'Client/pages/proposals/utils';
import { getContributionUserId } from 'Client/services/contributions';
import {
  getLocalItem,
  setLocalItem,
  CONFIRMATION_EMAIL_SENT_ITEM,
  CONTRIBUTION_SESSION_ITEM,
} from 'Client/utils/localstorage';
import { ErrorStatusOrHelperText, LoadingButtonStates, Separator } from 'Atoms';
import { PinIcon } from 'Atoms/Icons';
import { reverseGeocode } from 'Client/services/geocode/reverseGeocode';
import { fetchOrCreateUserDemographics } from 'Client/services/demographics';
import { GET_MAP_QUESTIONS } from 'Client/services/map/getMapQuestions.gql';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { SlidePanel } from '../MapSlidePanel';
import {
  Button,
  CancelButton,
  FormButtonsContainer,
  LoaderContainer,
  PanelHeader,
  PanelIcon,
  PanelTitle,
  QuestionWrapper,
  PinAddressContainer,
} from './ContributionFlowPanel.styles';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

export const ContributionFlowPanel = (): JSX.Element => {
  const {
    dispatch,
    state: { proposal, contributionFlow, xyz, draftContributionLayer },
  } = useMap();
  const { apiToken } = useUtils();
  const project = useProject();
  const { user: loggedInUser } = useUser();
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [sentiment, setSentiment] = React.useState('');
  const [questions, setQuestions] = React.useState([]);
  const [coordAddress, setCoordAddress] = React.useState<string>('');
  const [sentimentQuestion, setSentimentQuestion] = React.useState<Question>();
  const [loading, setLoading] = React.useState(true);
  const [isHandlingSubmit, setIsHandlingSubmit] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [errorStates, setErrorState] = React.useState([]);
  const [uploadToS3] = useMutation(UPLOAD_FILE_TO_S3);
  const canShowPinLocationAddress = !!project?.features?.geocoding;
  const [getMapQuestions] = useLazyQuery(GET_MAP_QUESTIONS);

  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await getMapQuestions({
          variables: {
            getMapPageQuestionsInput: {
              questionIds: proposal.questions,
              sentimentQuestionId: proposal.sentimentQuestion,
              lang: i18n.language,
            },
          },
        });
        const res = data.getMapPageQuestions;
        setQuestions(res.questions);
        setSentimentQuestion(res.sentimentQuestion);
      } catch (err) {
        setQuestions([]);
        setSentimentQuestion(undefined);
        captureException(
          `Error in useEffect (line  103) @ ContributionFlowPanel.tsx: ${err}`
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [proposal]);
  const response: Promise<MapBoxApiResponse> = React.useMemo(async () => {
    try {
      const coords = contributionFlow?.geometry?.coordinates;

      if (!canShowPinLocationAddress) {
        return;
      }

      const input = `${coords[0]},${coords[1]}`;
      const otherParameters = {};

      const response = await fetch(`/api/map/fetchGeocodingCoords`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          input,
          otherParameters,
        }),
      });

      return response.json();
    } catch (err) {
      captureException(
        `Error in mapBox api call (line 109) @ ContributionFlowPanel/index.tsx: ${err}`
      );
    }
  }, [contributionFlow?.geometry]);

  const getPinLocation = React.useCallback(() => {
    response.then((res) =>
      setCoordAddress(res?.suggestions?.[0]?.place_formatted)
    );
  }, [contributionFlow?.geometry]);

  React.useEffect(() => {
    if (
      contributionFlow &&
      contributionFlow.type === ContributionType.COMMENT &&
      contributionFlow.geometry &&
      xyz
    ) {
      try {
        const { coordinates } = contributionFlow.geometry;
        const coordsMapped = fromLonLat(coordinates);
        xyz.map.getView().animate({ center: coordsMapped });
        if (canShowPinLocationAddress) {
          getPinLocation();
        }
      } catch (err) {
        captureException(
          `Error in useEffect (line 108) @ ContributionFlowPanel/index.tsx: ${err}`
        );
      }
    }
  }, [xyz, contributionFlow]);

  React.useEffect(() => {
    const handleRouteChange = () => {
      setIsHandlingSubmit(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const trackEventIfFirstAnswer = () => {
    const isFirstAnswer =
      Object.keys(contributionFlow?.data || {}).length === 0;
    const isSentimentAnswered = Number.isInteger(sentiment);
    if (isFirstAnswer && !isSentimentAnswered) {
      // trigger the first time a question is answered.
      // Anwsers are saved in the context with 1 change lag.
      // Sentiment questions are not saved in the context at all.
      // So if the context is empty, and the sentiment in local state is empty
      // it is truly the first trigger of an answer
      trackEvent(MixpanelEventTypes.ANSWERING_QUESTIONS, {
        path: router.asPath,
        contributionId: contributionFlow?.contributionId,
      });
    }
  };
  const updateAnswers = (question: Question, value: QuestionValue) => {
    dispatch({
      type: 'SET_COMMENT_DATA',
      payload: { [question.id]: value },
    });
  };
  const validateContribution = () => {
    if (sentiment === undefined || sentiment === '') {
      setErrorState((current) => [...current, sentimentQuestion.id]);
      document
        .getElementById(sentimentQuestion.id)
        .scrollIntoView({ behavior: 'smooth' });
      return false;
    }
    for (const question of questions) {
      if (!question.required) continue;
      if (
        (contributionFlow?.data || {})[question.id] === undefined &&
        (contributionFlow?.voiceAnswers || {})[question.id] === undefined
      ) {
        setErrorState((current) => [...current, question.id]);
        document
          .getElementById(sentimentQuestion.id)
          .scrollIntoView({ behavior: 'smooth' });
        return false;
      }
    }
    return true;
  };
  const saveContribution = async () => {
    setIsHandlingSubmit(true);
    setErrorMessage(null);
    if (!validateContribution()) {
      setIsHandlingSubmit(false);
      setErrorMessage({
        message: t(
          'You have not answered a mandatory question, please go back and check.'
        ),
        type: 'error',
      });
      return;
    }
    const userId = getContributionUserId({
      user: loggedInUser,
      storedUserId: getLocalItem(CONTRIBUTION_SESSION_ITEM),
    });
    const answers = {
      ...contributionFlow.data,
      [sentimentQuestion.id]: sentiment,
    };
    const [lat, lng] = contributionFlow.geometry.coordinates;
    const { newGeocode } = await reverseGeocode(lat, lng);
    const saveAnswersOnPostgres =
      proposal?.featureFlags?.saveMapAnswersOnPostgres;

    try {
      const { demographics } = await fetchOrCreateUserDemographics({
        user: loggedInUser,
        userId: userId,
        projectName: project.id,
        language: i18n.language,
      });

      const contrDataForAcorn = {
        userId,
        sentiment,
        answers,
        voiceAnswers: contributionFlow?.voiceAnswers,
        draft: false,
        language: i18n.language,
        newGeocode,
        saveMapAnswersOnPostgres: saveAnswersOnPostgres,
        demographicsId: demographics?._id,
        status: loggedInUser
          ? ContributionStatus.CONFIRMED
          : userId
          ? ContributionStatus.PENDING
          : ContributionStatus.ANONYMOUS,
      };
      await fetch(`/api/contributions/${contributionFlow.contributionId}`, {
        method: 'PATCH',
        body: JSON.stringify(contrDataForAcorn),
      });

      if (project?.features?.trackContributionFlow) {
        trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
          fileName:
            'src/client/components/molecules/ContributionFlowPanel/ContributionFlowPanel.tsx',
          functionName: 'saveContribution',
          database: 'acorn',
          fieldToBeUpdated: Object.keys(contrDataForAcorn),
          gaudiUpdate: null,
          acornUpdate: contrDataForAcorn,
          userId: contrDataForAcorn?.userId,
          demographicsId: contrDataForAcorn?.demographicsId,
          contributionId: contributionFlow.contributionId,
        });
      }
      if (!contributionFlow.contributionId) {
        captureMessage('no contributionId when adding comment', {
          tags: {
            file: 'ContributionFlowPanel/index.tsx',
            method: 'PATCH',
          },
          user: {
            id: userId,
            email: loggedInUser?.email,
            name: loggedInUser?.firstName,
          },
        });
      }
      trackEvent(MixpanelEventTypes.ADDED_COMMENT, {
        path: router.asPath,
        contributionId: contributionFlow.contributionId,
        feeling: Number.isInteger(sentiment) ? sentiment : 'N/A',
      });
      const ans = questions
        ? makeAnswersForGaudi(questions, contributionFlow.data as Answers)
        : {};
      const contrDataForGaudi = {
        _id: contributionFlow.contributionId,
        project: project.id,
        schemeId: 'proposals',
        sectionId: proposal.slug,
        language: i18n.language,
        status: loggedInUser
          ? ContributionStatus.CONFIRMED
          : userId
          ? ContributionStatus.PENDING
          : ContributionStatus.ANONYMOUS,
        user_id: userId,
        origin: 'commonplace',
        survey: false,
        type: ContributionType.COMMENT,
        date: new Date(),
        syncHash: uuid(),
        latitude: contributionFlow.geometry[0],
        longitude: contributionFlow.geometry[1],
        feeling: sentiment,
        demographics_id: demographics?._id,
        ...ans,
      };
      await fetch(`/api/contributions?originDb=gaudi`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(contrDataForGaudi),
      });
      if (project?.features?.trackContributionFlow) {
        trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
          fileName:
            'src/client/components/molecules/ContributionFlowPanel/ContributionFlowPanel.tsx',
          functionName: 'saveContribution',
          database: 'gaudi',
          fieldToBeUpdated: Object.keys(contrDataForGaudi),
          gaudiUpdate: contrDataForGaudi,
          acornUpdate: null,
          userId: contrDataForGaudi?.user_id,
          demographicsId: contrDataForGaudi?.demographics_id,
          contributionId: contributionFlow.contributionId,
        });
      }
      await Promise.all(
        Object.keys(contributionFlow?.voiceAnswers || {}).map(async (key) => {
          const blob = await fetch(contributionFlow?.voiceAnswers[key]).then(
            (r) => r.blob()
          );
          const form = new FormData();
          form.append(
            'file',
            blob,
            `${contributionFlow.contributionId}_${key}.wav`
          );
          uploadToS3({ variables: { file: form.get('file') } });
        })
      );
      xyz.map.getInteractions().forEach((interaction) => {
        if (interaction instanceof Draw) {
          interaction.setActive(false);
          draftContributionLayer.getSource().clear();
        }
      });
      await handleProposalEndFlow({
        isMap: true,
        project,
        user: loggedInUser,
        storedUserId: getLocalItem(CONTRIBUTION_SESSION_ITEM),
        proposalSlug: proposal.slug,
        router,
        contributionId: contributionFlow.contributionId,
        confEmailSent: getLocalItem(CONFIRMATION_EMAIL_SENT_ITEM),
        setLocalItem,
        apiToken,
      });
      // Clear answers state right after finish contribution save
      dispatch({ type: 'CLEAR_ANSWERS' });
      dispatch({ type: 'CLEAR_VOICE_ANSWERS' });
      dispatch({ type: 'CLEAR_LEFT_PANEL' });
      dispatch({
        type: 'SET_CONTRIBUTION_FLOW_STARTED',
        payload: false,
      });
    } catch (error) {
      captureException(
        `Error in saveContribution @ ContributionFlowPanel/index.tsx , failed to save comment: ${error}`
      );
      setIsHandlingSubmit(false);
    }
  };

  const closePanel = async () => {
    // Close panel - with contributionFlow object state still populated,
    // means the user wants to cancel the flow
    if (contributionFlow && contributionFlow.contributionId) {
      const sentimentAnswered = Number.isInteger(sentiment);
      const numOfAnswers =
        Object.keys(contributionFlow?.data || {}).length +
        (sentimentAnswered ? 1 : 0);
      trackEvent(MixpanelEventTypes.COMMENT_CANCELLED, {
        path: router.asPath,
        contributionId: contributionFlow.contributionId,
        numOfAnswers,
      });

      xyz.layers.list.Contributions.reload();
    }

    setIsHandlingSubmit(false);

    dispatch({ type: 'CLEAR_LEFT_PANEL' });
    dispatch({ type: 'CLEAR_CONTRIBUTION_FLOW' });
  };
  const renderQuestions = () => {
    // its possible there is no data so lets not crash...
    if (!sentimentQuestion) return null;
    // Adds 1 because of the sentiment question.
    const orderedQuestions = [...questions, sentimentQuestion].sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
    return orderedQuestions.map((question) => {
      const isSentiment = question.id === sentimentQuestion.id;
      if (isSentiment) {
        return (
          <QuestionWrapper
            isShowingPinLocation={canShowPinLocationAddress && !!coordAddress}
            key={sentimentQuestion.id}
            id={sentimentQuestion.id}
          >
            <QuestionRenderer
              value={sentiment}
              onChange={(_qId, val) => {
                trackEventIfFirstAnswer();
                setSentiment(val as string);
                setErrorState((current) =>
                  current.filter((qId) => qId !== sentimentQuestion.id)
                );
              }}
              key={sentimentQuestion.id}
              isMap
              question={sentimentQuestion}
              errorState={errorStates.includes(sentimentQuestion.id)}
            />
          </QuestionWrapper>
        );
      }
      return (
        <QuestionWrapper
          isShowingPinLocation={canShowPinLocationAddress && !!coordAddress}
          key={question.id}
          id={question.id}
        >
          <QuestionRenderer
            value={contributionFlow?.data?.[question.id]}
            voiceValue={contributionFlow?.voiceAnswers?.[question.id]}
            onChange={(_qId, val) => {
              trackEventIfFirstAnswer();
              updateAnswers(question, val);
              setErrorState((current) =>
                current.filter((qId) => qId !== question.id)
              );
            }}
            isMap
            question={question}
            errorState={errorStates.includes(question.id)}
          />
        </QuestionWrapper>
      );
    });
  };
  return (
    <SlidePanel onClose={closePanel}>
      <PanelHeader>
        <PanelIcon
          width="20"
          height="20"
          src="/static/android-chrome-filled-192x192.png"
        />
        <PanelTitle>{t('Have your say')}</PanelTitle>
        <Separator height={'0.125rem'} />
      </PanelHeader>
      {isHandlingSubmit && <LoadingScreen withLoadingRing={true} />}
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <>
          {coordAddress && canShowPinLocationAddress && (
            <PinAddressContainer data-testid="PinAddressContainer">
              <div>
                <PinIcon width={25} height={25} color={'#00a85a'} />
                <p>{coordAddress}</p>
              </div>
            </PinAddressContainer>
          )}
          {renderQuestions()}
          <FormButtonsContainer loading={loading}>
            {errorMessage && <ErrorStatusOrHelperText status={errorMessage} />}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <CancelButton
                data-testid="Map-ContributionFlowPanel-cancel-button"
                onClick={closePanel}
                inverse
              >
                {t('Cancel')}
              </CancelButton>
              <Button
                resetState={() => {}}
                data-testid="Map-ContributionFlowPanel-next-button"
                onClick={saveContribution}
                state={
                  isHandlingSubmit
                    ? LoadingButtonStates.LOADING
                    : LoadingButtonStates.INITIAL
                }
              >
                {t('Next')}
              </Button>
            </div>
          </FormButtonsContainer>
        </>
      )}
    </SlidePanel>
  );
};
