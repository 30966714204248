import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DashboardPagesTemplate as Template } from 'Templates';
import { useProject, useUser } from 'Client/utils/hooks';
import { fetchProjectSettings } from 'Client/services/project';
import {
  NewsPostAcornDb,
  NewsPostLocaleContent,
  NewsPostLocaleItem,
  NewsPostStage,
  // SentEmailCount,
} from 'Shared/types/news';
import { saveProjectNews } from 'Client/services/news';
// import { sendProjectNewsEmail } from 'Client/services/email';
import { makeRedirectDomain } from 'Client/utils/url';
import { fetchConsentsCount } from 'Client/services/consent';
import { CONSENT_TYPES } from 'Client/constants/consents';
import { WarningIcon } from 'Atoms/Icons/WarningIcon/WarningIcon';
import { ArrowBack } from 'Atoms/Icons/ArrowBack/ArrowBack';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { AddNewsForm } from './components';
import {
  NewsFormWrapper,
  ErrorMessage,
  WarningBanner,
  Title,
  Container,
  BannerContent,
  BannerFlag,
  BackButton,
  PublishButton,
  RightSideWrapper,
  FlexWrapper,
  PublishWithoutEmailButton,
} from './AddNewsPage.styles';
import {
  validateAllFieldsHaveValues,
  makeNewsEntriesForGaudi,
  // makeAllLocalesNewsItems,
} from './utils';
import { NewsImageForm } from './components/NewsImageForm/NewsImageForm';
import { ADD_NEWS_TILE_IMAGE, GET_NEWS_FROM_SLUG } from './AddNewsPage.gql';

const initNewsPostItem: NewsPostLocaleItem = {
  author: '',
  headline: '',
  content: '',
};

export const AddNewsPage: React.FC = () => {
  const router = useRouter();
  const [projectLocales, setProjectLocales] = React.useState(['en-GB']);
  const project = useProject();
  const { user } = useUser();
  const { t } = useTranslation('customer');
  const [addNewsTileimage] = useMutation(ADD_NEWS_TILE_IMAGE);
  const [slug, setSlug] = React.useState<string>('');
  const [getNewsBySlug, { data: existingSlugData, loading }] = useLazyQuery(
    GET_NEWS_FROM_SLUG,
    {
      variables: { slug, projectId: project?._id },
    }
  );
  const [allNewsPosts, setAllNewsPosts] = React.useState<NewsPostLocaleContent>(
    {
      'en-GB': initNewsPostItem,
    }
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [postingNews, setPostingNews] = React.useState(false);
  const [postingNewsWithoutEmail, setPostingNewsWithoutEmail] =
    React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [newsImage, setNewsImage] = React.useState('');
  const [altText, setAltText] = React.useState('');
  const [successWithoutEmail, setSuccessWithoutEmail] = React.useState(false);
  const { apiToken } = useUtils();

  const initialiseAllNewsPostsItems = (locales: Array<string>) => {
    const allNewsPostsContentInit = {};
    locales.forEach((locale) => {
      allNewsPostsContentInit[locale] = initNewsPostItem;
    });
    setAllNewsPosts(allNewsPostsContentInit);
  };

  const getProjectSettings = React.useCallback(async (projectId: string) => {
    const settings = await fetchProjectSettings({
      projectId,
      projectRequestsOnGql: project.features.projectRequestsOnGql,
      apiToken,
    });
    if (settings && settings?.locales) {
      setProjectLocales(settings.locales || ['en-GB']);
      initialiseAllNewsPostsItems(settings.locales);
    }
  }, []);

  React.useEffect(() => {
    getProjectSettings(project._id);
  }, [project._id, getProjectSettings]);

  const handleChange =
    (localeUpdated: string) => (fieldName: string, value: string) => {
      setErrorMessage('');
      const existingItem = allNewsPosts[localeUpdated];
      const newItem = { ...existingItem, [fieldName]: value };
      setAllNewsPosts({ ...allNewsPosts, [localeUpdated]: newItem });
    };

  React.useEffect(() => {
    if (existingSlugData?.getNewsBySlug?.status === 200) {
      setErrorMessage('This url is already being used by another news page.');
      return;
    }
  }, [existingSlugData, slug, loading]);

  console.log(loading, 'load');

  const handleSaveClick = async (
    sendEmail: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      // validate that all fields are there
      const isValid = validateAllFieldsHaveValues(allNewsPosts);
      if (!isValid) {
        setErrorMessage('Please fill in all fields for all languages.');
        return;
      }
      if (slug === '') {
        // if slug is empty do not save
        setErrorMessage(
          'The news headline for the English language is invalid. Only latin and non-special characters are allowed.'
        );
        return;
      }

      if (
        existingSlugData &&
        existingSlugData?.getNewsBySlug?.status === 200 &&
        existingSlugData?.getNewsBySlug?.news !== null
      ) {
        // if slug already exists do not save
        setErrorMessage('This url is already being used by another news page.');
        return;
      }

      setLoading(true);
      const date = new Date().toISOString();
      const { count } = await fetchConsentsCount({
        projectName: project.id,
        type: CONSENT_TYPES.PROJECT_NEWS,
        apiToken,
        fetchConsentsCountGqlEndpoint:
          project?.features?.fetchConsentsCountGqlEndpoint || false,
      });
      // const sentEmailCount: SentEmailCount = {
      //   count,
      //   date,
      //   userId: user._id,
      // };
      const newsForAcorn: NewsPostAcornDb = {
        projectId: project._id,
        userId: user._id,
        slug,
        date,
        stage: NewsPostStage.ACTIVE,
        content: allNewsPosts,
        newsImage,
        newsImageAlt: altText,
        // ...(sendEmail && { sentEmailCount }),
      };
      const newsForGaudi = makeNewsEntriesForGaudi({
        allNewsPosts,
        slug,
        date,
        userId: user._id,
        count,
        projectName: project.id,
        includeSentEmailCount: false,
      });

      const res = await saveProjectNews({
        acornNews: newsForAcorn,
        gaudiNews: { ...newsForGaudi, newsImage, newsImageAlt: altText },
      });

      const { errors } = await addNewsTileimage({
        variables: {
          input: {
            image: newsImage,
            newsId: String(res.data._id),
            alt: altText,
          },
        },
      });

      if (res.success && (!errors || !errors?.length)) {
        if (!sendEmail) {
          setLoading(false);
          setSuccess(true);
          router.push('/news');
          return;
        }

        // redirect to 1.0 email
        const redirectDomain = makeRedirectDomain(project.id);
        router.push(
          `http://${redirectDomain}/v4/dashboard/communications/news/${String(
            res.data._id
          )}/email`
        );

        // removed for now
        // const emailResponse = await sendProjectNewsEmail({
        //   project,
        //   newsItems: makeAllLocalesNewsItems(newsForAcorn, project.id),
        // });
        // if (emailResponse.success) {
        //   setLoading(false);
        //   setSuccess(true);
        //   router.push('/news');
        // } else {
        //   setLoading(false);
        //   setErrorMessage(emailResponse.message);
        // }
      } else {
        setLoading(false);
        setErrorMessage(res.message);
      }
    } catch (err) {
      setLoading(false);
      captureException(`Error in handleSaveClick @ AddNewsPage.tsx: ${err}`);
      setErrorMessage(
        `Unknown error when saving news posts or sending project news emails: ${err}`
      );
    }
  };

  const buttonsDisabled =
    postingNews ||
    postingNewsWithoutEmail ||
    success ||
    successWithoutEmail ||
    loading;

  const isUrlError =
    errorMessage === 'This url is already being used by another news page.';

  return (
    <Template>
      <Container>
        <Title>{t('Publish news article')}</Title>
        <WarningBanner>
          <BannerFlag />
          <BannerContent>
            <WarningIcon />
            <p>
              <span>{t('Important: ')}</span>
              {t(
                'embedded content and iframes are not compatible with emails. Instead, please use images with hyperlinks.'
              )}
            </p>
          </BannerContent>
        </WarningBanner>
        <FlexWrapper direction="column" gapInRem={1}>
          {projectLocales.map((locale) => (
            <NewsFormWrapper key={locale}>
              <h2>{t('News post for locale: {{locale}}', { locale })}</h2>
              <AddNewsForm
                newsPost={allNewsPosts[locale]}
                onChange={handleChange(locale)}
                slug={slug}
                type="add"
                locale={locale}
                setSlug={setSlug}
                getNewsBySlug={getNewsBySlug}
                error={isUrlError}
                errorMessage={isUrlError && errorMessage}
              />
            </NewsFormWrapper>
          ))}
          <NewsImageForm
            image={newsImage}
            altText={altText}
            setImage={setNewsImage}
            setAltText={setAltText}
          />
        </FlexWrapper>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Container>
      <footer className="add-news-footer">
        <BackButton onClick={() => router.push('/news')}>
          <ArrowBack width={16} height={15} />
          <span>{t('Go back to news')}</span>
        </BackButton>
        <RightSideWrapper>
          <PublishWithoutEmailButton
            onClick={() => {
              if (errorMessage !== '') return;
              handleSaveClick(
                false,
                setPostingNewsWithoutEmail,
                setSuccessWithoutEmail
              );
            }}
            disabled={buttonsDisabled}
          >
            {postingNewsWithoutEmail
              ? t('Posting news...')
              : successWithoutEmail
              ? t('Success!')
              : t('Publish without email')}
          </PublishWithoutEmailButton>
          <PublishButton
            data-onboarding="dd-news-submit-and-launch-email"
            data-testid="add-news-submit-and-launch-email"
            onClick={() => {
              if (errorMessage !== '') return;
              handleSaveClick(true, setPostingNews, setSuccess);
            }}
            disabled={buttonsDisabled}
          >
            {postingNews
              ? t('Posting news and sending email...')
              : success
              ? t('Success!')
              : t('Publish and send email')}
          </PublishButton>
        </RightSideWrapper>
      </footer>
    </Template>
  );
};
